<main fxFex fxLayout="column" fxLayoutAlign="Stretch">
  <mat-card appearance="outlined" fxLayout="column" fxLayout.xs="column">
    <mat-card-header class="card-header" fxLayout="column" fxFlex="30" fxFlex.lt-sm="100">
      <mat-card-title><strong>Levels of Cover</strong></mat-card-title>
      <mat-card-subtitle>Maintain your levels of cover here.</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
      <div>
        <button mat-button class="btn-table-row btn-create" (click)="createLevelOfCover()" [disabled]="invalidAddLoC()" matTooltip="Create new Levels of Cover">
          <mat-icon>add</mat-icon>
        </button>
      </div>

      <div [formGroup]="searchForm" class="search-group">
        <div fxLayout="row" fxLayoutAlign="end center">
          <div fxLayout="row" fxLayoutAlign="end center">
            <div class="section" >
              <app-shared-search (onSearch)="search()" (onValueChange)="onValueChange($event)" [placeholderText]="productId ? 'Search by sub product':'Search by Product or Sub product or Level of Cover'">
              </app-shared-search>
            </div>
          </div>
        </div>
        <div class="include-inactive">
          <label id="include-inactive-items" class="label-include"><span><strong>Include Inactive
                Items:</strong></span>
          </label>
          <mat-radio-group formControlName="isIncludedInactive">
            <mat-radio-button data-test="noRadio" value="false" [checked]="isIncludedInactive.value === false">No
            </mat-radio-button>
            <mat-radio-button data-test="yesRadio" value="true" [checked]="isIncludedInactive.value === true">Yes
            </mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
      <section [formGroup]="filterForm" *ngIf="!isFromProduct">
        <mat-form-field class="select-type-productId">
          <mat-label>Product</mat-label>
          <mat-select data-test="productId" formControlName="filterProductId" placeholder="Product">
            <mat-option value='' [matTooltip]="'All'">All</mat-option>
            <mat-option *ngFor="let p of products" [value]="p.id" [matTooltip]="p.name">
              {{p.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field class="select-type-subProductId">
          <mat-label>Sub Product</mat-label>
          <mat-select data-test="subProductId" formControlName="filterSubProductId" placeholder="Sub Product">
            <mat-option *ngFor="let p of subProducts" [value]="p.id" [matTooltip]="p.name">
              {{p.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field class="select-type-levelofcover">
          <mat-label>Level of Cover</mat-label>
          <mat-select data-test="levelOfCoverId" formControlName="filterLevelOfCoverId" placeholder="Level of Cover">
            <mat-option *ngFor="let l of levelOfCovers" [value]="l.id"  [matTooltip]="l.name">
              {{l.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>

      </section>
      <table mat-table [dataSource]="dataSource" matSort matSortActive="productName" matSortDirection="asc"
        matSortDisableClear>

        <ng-container matColumnDef="productName">
          <th mat-header-cell *matHeaderCellDef>Product</th>
          <td mat-cell *matCellDef="let row">{{row.productName}}</td>
        </ng-container>

        <ng-container matColumnDef="subProductName">
          <th mat-header-cell *matHeaderCellDef>Sub Product</th>
          <td mat-cell *matCellDef="let row">{{row.subProductName}}</td>
        </ng-container>

        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef>Level of Cover</th>
          <td mat-cell *matCellDef="let row">{{row.name}}</td>
        </ng-container>

        <ng-container matColumnDef="order">
          <th mat-header-cell *matHeaderCellDef>Order</th>
          <td mat-cell *matCellDef="let row">{{row.order}}</td>
        </ng-container>

        <ng-container matColumnDef="isActive">
          <th mat-header-cell *matHeaderCellDef>Active</th>
          <td mat-cell *matCellDef="let row">
            <mat-icon *ngIf="row.isActive" class="colour-success" svgIcon="check"></mat-icon>
            <mat-icon *ngIf="!row.isActive" class="colour-danger" svgIcon="close"></mat-icon>
          </td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef>Edit</th>
          <td mat-cell *matCellDef="let row">
            <button mat-button class="btn-action" (click)="editLevelOfCover(row)" matTooltip="Edit">
              <mat-icon svgIcon="pencil"></mat-icon>
            </button>
          </td>
        </ng-container>

        <ng-container matColumnDef="pricing">
          <th mat-header-cell *matHeaderCellDef>Pricing</th>
          <td mat-cell *matCellDef="let row">
            <button mat-button class="btn-action" (click)="addPricing(row)" matTooltip="Pricing">
              <mat-icon svgIcon="dots-horizontal-circle" colour="primary"></mat-icon>
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>

      <div fxFex fxLayout="row" fxLayoutAlign="space-between">
        <span class="not-found-text">{{notFoundMessage}}</span>
        <mat-paginator [length]="dataSource.totalRecords" [pageIndex]="pagingConstant.pageIndex"
          [pageSize]="pagingConstant.pageSizeLarger" [pageSizeOptions]="pageSizeOptions" showFirstLastButtons>
        </mat-paginator>
      </div>
    </mat-card-content>
  </mat-card>
</main>


  <mat-card fxLayout="column" fxLayout.xs="column" fxLayoutAlign="Stretch">
    <mat-card-header fxLayout="column" fxFlex="30" fxFlex.lt-sm="100">
      <mat-card-title><strong>Level of Cover Pricing</strong></mat-card-title>
      <mat-card-subtitle>
        Maintain your level of cover pricing here.
      </mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
      <div [formGroup]="searchForm" class="search-group">
        <div fxLayout="row" fxLayoutAlign="end center">
          <div class="section">
            <app-shared-search (onSearch)="search()" (onValueChange)="onValueChange($event)" [placeholderText]="'Search by product or sub product'">
            </app-shared-search>
          </div>
        </div>
        <div class="include-inactive">
          <label id="include-inactive-items" class="label-include"><span><strong>Include Inactive
                Items:</strong></span>
          </label>
          <mat-radio-group formControlName="isIncludedInactive">
            <mat-radio-button data-test="noRadio" [value]="false" [checked]="isIncludedInactive.value === false">No
            </mat-radio-button>
            <mat-radio-button data-test="yesRadio" [value]="true" [checked]="isIncludedInactive.value === true">Yes
            </mat-radio-button>
          </mat-radio-group>
        </div>
      </div>

      <section [formGroup]="filterForm">
        <mat-form-field class="select-type-productId">
          <mat-label>Product</mat-label>
          <mat-select data-test="productId" formControlName="productId" placeholder="Product">
            <mat-option value='' [matTooltip]="'All'">All</mat-option>
            <mat-option *ngFor="let p of products" [value]="p.id" [matTooltip]="p.name">
              {{p.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>


        <mat-form-field class="select-type-subProductId">
          <mat-label>Sub Product</mat-label>
          <mat-select data-test="subProductId" formControlName="subProductId" placeholder="Sub Product">
            <mat-option *ngFor="let p of subProducts" [value]="p.id" [matTooltip]="p.name">
              {{p.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field class="select-type-levelofcover">
          <mat-label>Level of Cover</mat-label>
          <mat-select data-test="levelOfCoverId" formControlName="levelOfCoverId" placeholder="Level of Cover">
            <mat-option *ngFor="let l of levelOfCovers" [value]="l.id" [matTooltip]="l.name">
              {{l.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>

      </section>

      <table mat-table [dataSource]="dataSource" matSort matSortActive="productName" matSortDirection="asc"
        matSortDisableClear>

        <ng-container matColumnDef="productName">
          <th mat-header-cell *matHeaderCellDef>Product</th>
          <td mat-cell *matCellDef="let row">{{row.productName}}</td>
        </ng-container>

        <ng-container matColumnDef="subProductName">
          <th mat-header-cell *matHeaderCellDef>Sub Product</th>
          <td mat-cell *matCellDef="let row">{{row.subProductName}}</td>
        </ng-container>

        <ng-container matColumnDef="levelOfCoverName">
          <th mat-header-cell *matHeaderCellDef>Level of Cover</th>
          <td mat-cell *matCellDef="let row">{{row.levelOfCoverName}}</td>
        </ng-container>


        <ng-container matColumnDef="priceType">
          <th mat-header-cell *matHeaderCellDef>Price Type</th>
          <td mat-cell *matCellDef="let row">{{row.priceType?row.priceType:'Standard'}}
          </td>
        </ng-container>

        <ng-container matColumnDef="brokerRate">
          <th mat-header-cell *matHeaderCellDef>Broker Rate</th>
          <td mat-cell *matCellDef="let row">{{row.brokerRate | currency:'GBP'}}</td>
        </ng-container>

        <ng-container matColumnDef="brokerRateIncludeIPT">
          <th mat-header-cell *matHeaderCellDef>Broker Rate Inc IPT</th>
          <td mat-cell *matCellDef="let row">{{row.brokerRateIncludeIPT | currency:'GBP'}}</td>
        </ng-container>

        <ng-container matColumnDef="minimumSaleRate">
          <th mat-header-cell *matHeaderCellDef>Minimum Sale Rate</th>
          <td mat-cell *matCellDef="let row">{{row.minimumSaleRate | currency:'GBP'}}</td>
        </ng-container>

        <ng-container matColumnDef="maximumRetailRate">
          <th mat-header-cell *matHeaderCellDef>Net Maximum Retail Rate</th>
          <td mat-cell *matCellDef="let row">{{row.maximumRetailRate | currency:'GBP'}}</td>
        </ng-container>

        <ng-container matColumnDef="underwriterRate">
          <th mat-header-cell *matHeaderCellDef>Underwriter Rate</th>
          <td mat-cell *matCellDef="let row">{{row.underwriterRate | currency:'GBP'}}</td>
        </ng-container>


        <ng-container matColumnDef="intermediaryCommission">
          <th mat-header-cell *matHeaderCellDef>Intermediary Commission</th>
          <td mat-cell *matCellDef="let row">{{row.intermediaryCommission | currency:'GBP'}}</td>
        </ng-container>

        <ng-container matColumnDef="effectiveDateFrom">
          <th mat-header-cell *matHeaderCellDef>Effective Date From</th>
          <td mat-cell *matCellDef="let row">{{row.effectiveDateFrom | date: 'dd/MM/yyyy'}}</td>
        </ng-container>

        <ng-container matColumnDef="effectiveDateTo">
          <th mat-header-cell *matHeaderCellDef>Effective Date To</th>
          <td mat-cell *matCellDef="let row">{{row.effectiveDateTo | date: 'dd/MM/yyyy'}}</td>
        </ng-container>

        <ng-container matColumnDef="isActive">
          <th mat-header-cell *matHeaderCellDef>Active</th>
          <td mat-cell *matCellDef="let row">
            <mat-icon *ngIf="row.isActive" svgIcon="check" class="colour-success"></mat-icon>
            <mat-icon *ngIf="!row.isActive" svgIcon="close" class="colour-danger"></mat-icon>
          </td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef>Edit</th>
          <td mat-cell *matCellDef="let row">
            <button mat-button class="btn-action" (click)="editPricing(row)" matTooltip="Edit pricing">
              <mat-icon svgIcon="pencil"></mat-icon>
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>

      <div fxFex fxLayout="row" fxLayoutAlign="space-between">
        <span class="not-found-text">{{notFoundMessage}}</span>
        <mat-paginator [length]="dataSource.totalRecords" [pageIndex]="pagingConstant.pageIndex"
          [pageSize]="pagingConstant.pageSizeLarger" [pageSizeOptions]="pagingConstant.pageSizeOptionsLarger" showFirstLastButtons>
        </mat-paginator>
      </div>
    </mat-card-content>
  </mat-card>

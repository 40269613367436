import { AbstractControl } from '@angular/forms';

export function noSpecialCharacterValidator(control: AbstractControl) {
    const format = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;

    if (control.value) {
        if (format.test(control.value)) {
          return { specialCharacter: { value: control.value, message: 'Invalid character entered. Please retry.' } };
        } else {
          return null;
        }
    }
    return null;
};

export function noSpecialCharacterValidatorButNotDot(control: AbstractControl) {
  const format = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?~]/;

  if (control.value) {
      if (format.test(control.value)) {
        return { specialCharacter: { value: control.value, message: 'Invalid character entered. Please retry.' } };
      } else {
        return null;
      }
  }
  return null;
};
